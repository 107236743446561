<template>
	<div id="wrapper" class="main" v-cloak>
		<!-- 헤더 -->
		<header id="header">
			<h1 class="logo">
				<a style="cursor: default">
					<img src="/dashboardlib/images/common/logo.png" alt="A safe / Always by your side 에이세이프" />
				</a>
			</h1>
			<span class="header_date">{{ todayDate }}</span>

			<ul class="header_btn">
				<li v-for="(item, index) in divisionBtn" :key="index" class="btn_item" :class="[item.n, item.activeClass]">
					<a @click="divisionBtnClicked(index)"></a>
				</li>
			</ul>
		</header>
		<!-- //헤더 -->

		<!-- 본문 -->
		<div id="container">
			<!-- 왼쪽 본문 -->
			<div class="cont_left_body">
				<!-- 위험상황 & 조치중 -->
				<div class="situation">
					<ul class="situation_list">
						<li class="situation_item n1">
							<span class="situation_count">
								<em>{{ count.danger }}</em>
								건
							</span>
							<span class="situation_title">위험상황</span>
						</li>
						<li class="situation_item n2">
							<span class="situation_count">
								<em>{{ count.action }}</em>
								건
							</span>
							<span class="situation_title">조치중</span>
						</li>
					</ul>
				</div>
				<!-- //위험상황 & 조치중 -->

				<!-- CCTV목록 -->
				<div class="cctv">
					<ul class="cctv_list">
						<li v-for="(item, index) in leftCctv" :key="index" :class="[item.class]" class="cctv_item">
							<span
								class="cctv_name"
								style="width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
								:class="[item.type]"
								:title="item.cctvNm"
							>
								{{ item.cctvNm }}
							</span>
							<div class="switch_wrapper">
								<input type="checkbox" class="switch" :id="'switch' + index" @click="cctvOnOff(index)" />
								<label :for="'switch' + index" class="switch_label">
									<span class="onf_btn"></span>
								</label>
							</div>
						</li>
					</ul>
				</div>
				<!-- //CCTV목록 -->
			</div>
			<!-- //왼쪽 본문 -->

			<!-- 오른쪽 본문 -->
			<div class="cont_right_body">
				<!-- 오른쪽 본문 상단 -->
				<div class="cont_right_body_top">
					<!-- 주의작업 -->
					<div class="caution">
						<div class="caution_left">
							<span class="caution_title">주의작업</span>
							<span class="caution_count">
								<em>{{ count.caution }}</em>
								건
							</span>
						</div>
						<div class="caution_notice">
							<ul class="notice_list" style="width: inherit">
								<li class="notice_item" v-for="(item, index) in dangerContent" :key="index">· {{ item.text }}</li>
							</ul>
						</div>
					</div>
					<!-- //주의작업 -->

					<!-- 날씨정보 -->
					<div class="weather">
						<ul class="weather_list">
							<li class="weather_item temp">
								<div class="temp_text1">{{ weather.temperature }}℃</div>
							</li>
							<li class="weather_item temp">
								<div class="temp_text2">
									<span style="display: block">↑{{ weather.temperatureMax }}℃</span>
									<span style="display: block">↓{{ weather.temperatureMin }}℃</span>
								</div>
							</li>
							<li class="weather_item rain">
								<!-- <span class="fas weather text-primary" v-bind:class="weather.weatherIcon">
								<div class="rain_icon fas fa-4x" :class="weather.weatherIcon"></div> -->
								<div class="rain_icon type01">맑음</div>
							</li>
							<li class="weather_item rain">
								<div class="rain_text">0mm</div>
							</li>
							<li class="weather_item info">
								<ul class="info_list">
									<li class="info_item"></li>
									<li class="info_item">
										습도 :
										<em>{{ weather.humidity }}</em>
										%
									</li>
									<li class="info_item">
										풍속 :
										<em>{{ weather.windSpeed }}</em>
										m/s
									</li>
								</ul>
							</li>
						</ul>
					</div>
					<!-- //날씨정보 -->
				</div>
				<!-- //오른쪽 본문 상단 -->

				<!-- cctv화면목록 -->
				<div class="screen" :class="[division]">
					<ul class="screen_list"></ul>
				</div>
				<!-- //cctv화면목록 -->
			</div>
			<!-- //오른쪽 본문 -->
		</div>
		<!-- //container -->

		<!-- 푸터 -->
		<footer id="footer">
			<span class="footer_copy">Copyright © AIKL SYSTEM Inc. All Rights Reserved.</span>
		</footer>
		<!-- //푸터 -->
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import window from '../../lib/window';
import VueCookies from 'vue-cookies';
import _ from 'lodash';

const dashboardUrl = apiIndex.dashboardAll;
const mainUrl = apiIndex.main;

let axiosExtention;
axiosExtention;

export default {
	data: () => ({
		pageParam: {
			inqSite: '',
			inqSiteLength: '',
		},
		loginInfo: null,
		scrollPosition: null,
		todayDate: '',
		// CCTV 화면 분할 조절할 class 변수
		divisionBtn: [
			{ n: 'n1', activeClass: 'active' },
			{ n: 'n2', activeClass: '' },
			{ n: 'n3', activeClass: '' },
		],
		division: 'div4', // CCTV 화면 분할 조절할 class 변수
		count: {
			danger: 0, // 위험상황 건수
			action: 0, // 조치중 건수
			caution: 0, // 주의작업 건수
		},
		tick: 1, // 일정 시간마다 데이터 reload 용
		dangerContent: [],
		weather: {
			weatherIcon: '',
			temperature: 0,
			temperatureMin: 0,
			temperatureMax: 0,
			humidity: 0,
			windSpeed: 0,
		},
		leftCctv: [], // left layout CCTV 정보
		// videojs options
		playerOptions: {
			autoplay: true,
			controls: false,
			muted: true,
			loop: false,
			sources: [
				{
					type: 'application/x-mpegurl',
					src: '', // 설정시 변경됨
				},
			],
		},
		// cctv 상태가 위험으로 변경 시 popup 띄우기 위해 사용
		preCctvState: [],
		maxDangerOccurEvents: 5,
		forRerendering: 0,
		// cctv상태가 위험으로 변경시 나타난 popup을 x초 뒤에 없앰
		secTimeSettingForRemovingWindow: 10,
		//img, live
		dangerPopupOption: null,
		//위험발생팝업 스위치용
		originDangerOccurInfo: {},
		// 위험발생팝업 on off
		dangerPopupOnOff: false,
	}),
	created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;

		// pageParam 로딩
		this.pageParam = JSON.parse(localStorage.getItem('dashboardAllPageParam'));
		this.loginInfo = JSON.parse(localStorage.getItem('loginUserInfo'));

		// 탭 상단 이름 변경
		let title = document.getElementsByTagName('title')[0];
		title.innerHTML = '에이세이프';

		this.init();
	},
	computed: {},
	mounted() {},
	watch: {},
	methods: {
		addZero(string) {
			// 값이 1자리면 앞에 '0' 붙임
			return string[1] ? string : '0' + string[0];
		},
		clock() {
			// 현재 시각
			const date = new Date();
			const yyyy = date.getFullYear().toString();
			const mm = (date.getMonth() + 1).toString();
			const dd = date.getDate().toString();
			const h = date.getHours().toString();
			const m = date.getMinutes().toString();
			const s = date.getSeconds().toString();
			const week = ['일', '월', '화', '수', '목', '금', '토'];

			this.todayDate =
				yyyy +
				'.' +
				this.addZero(mm) +
				'.' +
				this.addZero(dd) +
				'. ' +
				week[date.getDay()] +
				' ' +
				this.addZero(h) +
				':' +
				this.addZero(m) +
				':' +
				this.addZero(s);

			// 30초마다 reload?
			if (this.tick++ % 5 !== 0) return;

			// cctv 정보도 가져와야하나..? 그럴 필요까지는 없어보이기는 함
			this.reload();
			this.refreshCctv();

			this.tick = 1;
		},
		init() {
			this.clock(); // 딜레이 없이 바로 표시하기 위함
			setInterval(() => {
				this.clock();
			}, 1000); // 1초 마다 시간 변경
			// }, 60000); // 1분 마다 시간 변경

			this.initCctv();
			this.reload();

			let index = VueCookies.get(this.loginInfo.userId + '_allSitedivisionOption');
			if (index) {
				// cctv 보이는 레이아웃 설정하기
				this.divisionBtnClicked(index);
			}
		},
		initCctv() {
			const param = {};

			// CCTV 정보를 가져온다.
			this.$axios
				.post(dashboardUrl.inqCctv, param)
				.then(
					function (response) {
						response.data.forEach((e, index) => {
							e.fixIndex = index;
							e.playerOptions = $.extend(true, {}, this.playerOptions); // deep 복사 안하면 전부 같은 주소를 본다
							e.playerOptions.sources[0].src = e.cctvLivePath;
						});
						this.leftCctv = response.data;
						var count = 0;
						this.leftCctv.forEach(e => {
							e.cctvPlayUrl += '/' + e.cctvId;
							//TEMP
							// e.cctvPlayUrl = process.env.VUE_APP_CCTV_PLAY_URL + e.cctvPlayUrl.substr(e.cctvPlayUrl.lastIndexOf(':')) + '/' + e.cctvId;
							// cctv class 초기화
							e.type = 'type2';
							this.$nextTick(function () {
								this.cctvOnOff(count++);
							});
						});
					}.bind(this),
				)
				.catch(err => {
					console.log(err);
				});
		},
		removeDangerOccurEventWhenNumberOfEventsOverMax(cctvInfo) {
			if (cctvInfo.dangerOccurEvent != undefined) {
				if (cctvInfo.dangerOccurEvent.length > this.maxDangerOccurEvents) {
					cctvInfo.dangerOccurEvent.splice(0, cctvInfo.dangerOccurEvent.length - this.maxDangerOccurEvents);
					this.forRerendering += 1;
				}
			}
		},
		setFontSizeDangerOccurEventInfos() {
			setInterval(() => {
				let div = document.getElementsByClassName('sliding_info');
				if (div != undefined && div.length > 0) {
					let minSlideWidth = parseInt(div[0].offsetWidth);
					// 제일 작은 Slide Width 기준으로
					// 첫번째 div가 화면이 안나오면 무조건 제일 큰 값으로 고정되어서 이렇게 수정함
					for (let i = 1; i < div.length; i++) {
						if (minSlideWidth > parseInt(div[i].offsetWidth)) {
							minSlideWidth = parseInt(div[i].offsetWidth);
						}
					}
					let slideWidth = minSlideWidth;
					if (slideWidth > 250) {
						$('.dangerOccurEventInfos').css('font-size', '25px');
					} else if (slideWidth > 150) {
						$('.dangerOccurEventInfos').css('font-size', '12px');
					} else {
						$('.dangerOccurEventInfos').css('font-size', '5px');
					}
				}
			}, 500);
		},
		setDangerOccurEventInfos(cctvInfo, data) {
			this.originDangerOccurInfo = _.cloneDeep(cctvInfo.dangerOccurEvent);
			cctvInfo.dangerOccurEvent = [];
			//위험발생상황 추가하기
			data.inqDashboardDangerOccurOutVO.forEach(danger => {
				if (danger.cctvId == cctvInfo.cctvId) {
					if (danger.frameImgSeq != cctvInfo.dangerOccurEvent.frameImgSeq || cctvInfo.dangerOccurEvent.objectId != danger.objectId) {
						cctvInfo.dangerOccurEvent.push(danger);
					}
				}
			});
			cctvInfo.dangerOccurEvent.forEach(danger => {
				if (this.originDangerOccurInfo) {
					this.originDangerOccurInfo.forEach(originDanger => {
						if (danger.dangerOccurObjectSeq == originDanger.dangerOccurObjectSeq) {
							danger.slidein = false;
						}
					});
				}
			});
			this.removeDangerOccurEventWhenNumberOfEventsOverMax(cctvInfo);

			// CCTV 위험 이벤트 정보에서 없어진 event div 삭제
			let eventDivLists = [];
			if (data) {
				if (data.inqDashboardDangerOccurOutVO) {
					data.inqDashboardDangerOccurOutVO.forEach(danger => {
						// 현재 발생된 이벤트 값만 저장
						eventDivLists.push('event-div' + danger.dangerOccurObjectSeq + danger.objectId);
					});
				}
			}

			let slidingInfo = document.getElementsByClassName('sliding_info');
			if (slidingInfo) {
				// sliding_info 클래스의 자식들 중에서
				for (let i = 0; i < slidingInfo.length; i++) {
					for (let j = 0; j < slidingInfo[i].children.length; j++) {
						// eventDivLists에 없는 id면 삭제
						if (eventDivLists.findIndex(e => e === slidingInfo[i].children[j].getAttribute('id')) == -1) {
							slidingInfo[i].children[j].remove();
						}
					}
				}
			}

			// CCTV 위험 이벤트 정보를 가지고 event div 생성
			let dangerOccurEvent = cctvInfo.dangerOccurEvent;
			if (dangerOccurEvent && dangerOccurEvent.length > 0) {
				dangerOccurEvent.forEach(e => {
					if (e.videoDangerActnDvsnCd != '03') {
						// 조치중 상태가 아니고, 슬라이드 css 발생하지 않은 상태
						let cctvSlidingDiv = document.getElementById('cctv-sliding-div' + e.cctvId);
						if (cctvSlidingDiv) {
							let checkEventDiv = document.getElementById('event-div' + e.dangerOccurObjectSeq + e.objectId);
							if (checkEventDiv == null) {
								{
									// div class=dangerOccurEvent slide_in
									let eventDiv = document.createElement('div');
									eventDiv.setAttribute('id', 'event-div' + e.dangerOccurObjectSeq + e.objectId);
									eventDiv.classList.add('dangerOccurEvent');
									eventDiv.classList.add('slide_in');
									cctvSlidingDiv.appendChild(eventDiv);
									{
										// div class=dangerOccurEventInfos
										let dangerOccurEventInfos = document.createElement('div');
										dangerOccurEventInfos.classList.add('dangerOccurEventInfos');
										eventDiv.appendChild(dangerOccurEventInfos);
										{
											//h3
											let h3 = document.createElement('h3');
											h3.textContent = e.objectNm;
											dangerOccurEventInfos.appendChild(h3);

											//span
											let span1 = document.createElement('span');
											span1.textContent = e.targetNm;
											if (e.dangerZoneNm != null) {
												span1.textContent += ' ' + e.dangerZoneNm;
											}
											dangerOccurEventInfos.appendChild(span1);

											//br
											let br = document.createElement('br');
											dangerOccurEventInfos.appendChild(br);

											//span
											let span2 = document.createElement('span');
											span2.textContent = e.dangerOccurDtm;
											dangerOccurEventInfos.appendChild(span2);
										}
									}
								}
							}
						}
					} else if (e.videoDangerActnDvsnCd == '03') {
						// 조치중 상태이고, 슬라이드 css 발생하지 않은 상태
						let cctvSlidingDiv = document.getElementById('cctv-sliding-div' + e.cctvId);
						if (cctvSlidingDiv) {
							{
								let checkEventDiv = document.getElementById('event-div' + e.dangerOccurObjectSeq + e.objectId);
								if (checkEventDiv == null) {
									// div class=dangerOccurEvent slide_in
									let eventDiv = document.createElement('div');
									eventDiv.setAttribute('id', 'event-div' + e.dangerOccurObjectSeq + e.objectId);
									eventDiv.classList.add('dangerOccurEvent_actnInProgress');
									eventDiv.classList.add('slide_in');
									cctvSlidingDiv.appendChild(eventDiv);
									{
										// div class=dangerOccurEventInfos
										let dangerOccurEventInfos = document.createElement('div');
										dangerOccurEventInfos.classList.add('dangerOccurEventInfos');
										eventDiv.appendChild(dangerOccurEventInfos);
										{
											//h3
											let h3 = document.createElement('h3');
											h3.textContent = e.objectNm + '  ---- 조치중';
											dangerOccurEventInfos.appendChild(h3);

											//span
											let span1 = document.createElement('span');
											span1.textContent = e.targetNm;
											if (e.dangerZoneNm != null) {
												span1.textContent += ' ' + e.dangerZoneNm;
											}
											dangerOccurEventInfos.appendChild(span1);

											//br
											let br = document.createElement('br');
											dangerOccurEventInfos.appendChild(br);

											//span
											let span2 = document.createElement('span');
											span2.textContent = e.dangerOccurDtm;
											dangerOccurEventInfos.appendChild(span2);
										}
									}
								}
							}
						}
					} else {
						console.log("If you come in here, it's a weird case, so please check");
					}
				});
			}

			this.setFontSizeDangerOccurEventInfos();
		},
		reload() {
			// 주기적으로 날씨, 건수, 주의 작업 텍스트 가져옴

			// 위험상황, 조치중, 주의 작업, 금일 발생, 조치 완료, 잔여 + 주의작업 contents 가져오기 (DB)

			const param = {};
			// 위험상황, 조치중, 주의 작업 건수 + 주의작업 contents 가져온다
			this.$axios
				.post(dashboardUrl.reload, param)
				.then(
					function (response) {
						this.count.danger = response.data.dangerOccurCnt; //위험상황
						this.count.action = response.data.dangerActionCnt; // 조치중
						this.count.caution = response.data.warnWorkOccurCnt; // 주의작업
						// 주의 작업 내용 편집
						response.data.inqDashboardWarnWorkOutVOs.forEach(e => {
							e.text = '[' + e.cctvInstallPlaceCont + ']';
							e.text += ' ' + e.objectNm + ' 진행 중';
						});

						this.dangerContent = response.data.inqDashboardWarnWorkOutVOs;
						// cctv 색 변경
						this.leftCctv.forEach(e => {
							// //위험발생상황 추가하기
							this.setDangerOccurEventInfos(e, response.data);
							let isDanger = false;
							for (let i = 0; i < response.data.dangerOccurCctvIds.length; i++) {
								if (e.cctvId === response.data.dangerOccurCctvIds[i]) {
									// 조회된 값이면 빨간색으로 바뀸
									isDanger = true;
									break;
								}
							}
							//위험상황시에만 CCTV가 빨갛게 바뀌도록 수정.
							//FIX ME: 주의상황시에도 해당이 되는지?
							// for (let i = 0; i < response.data.warnWorkOccurCctvIds.length; i++) {
							// 	if (e.cctvId === response.data.warnWorkOccurCctvIds[i]) {
							// 		isDanger = true;
							// 		break;
							// 	}
							// }
							if (isDanger) {
								e.type = 'type1';
							} else {
								e.type = 'type2';
							}
						});

						// FIX ME: 캡처 시 팝업이 옆에 슬라이드 보다 살짝 늦게뜨는 구조임.. 구조 변경 필요
						this.chkPopupCctv(response.data);
					}.bind(this),
				)
				.catch(err => {
					console.log(err);
				});
		},
		// cctv상태가 위험으로 변경되면 popup띄움
		chkPopupCctv(data) {
			let that = this;

			// 위험 발생 팝업이 on일 때만 팝업을 띄움
			if (this.dangerPopupOnOff) {
				if (this.preCctvState.length != this.leftCctv.length) {
					this.preCctvState = JSON.parse(JSON.stringify(this.leftCctv));
				} else {
					this.leftCctv.forEach(cctv => {
						for (let i = 0; i < that.preCctvState.length; i++) {
							if (cctv.cctvId === that.preCctvState[i].cctvId) {
								if (cctv.type !== that.preCctvState[i].type && cctv.type === 'type1') {
									that.dangerCctvPopup(cctv, 'dangerOccurPopup', data);
								}
								that.preCctvState[i].type = cctv.type;
							}
						}
					});
				}
			}
		},
		async dangerCctvPopup(item, flag, data) {
			for (let i = 0; i < data.inqDashboardDangerOccurOutVO.length; i++) {
				// 위험이 발생한 cctv의 관련 정보를 넣어줌
				if (item.cctvId === data.inqDashboardDangerOccurOutVO[i].cctvId) {
					item.objectId = data.inqDashboardDangerOccurOutVO[i].objectId;
					item.objectNm = data.inqDashboardDangerOccurOutVO[i].objectNm;
					item.targetId = data.inqDashboardDangerOccurOutVO[i].targetId;
					item.targetNm = data.inqDashboardDangerOccurOutVO[i].targetNm;
					item.strtFrameImgSeq = data.inqDashboardDangerOccurOutVO[i].strtFrameImgSeq;
					break;
				}
			}
			if (this.dangerPopupOption == 'img') {
				await this.$axios
					.post(mainUrl.imgRealtimeEvent, item)
					.then(r => {
						item.dangerOccurImg = 'data:image/jpg;base64,' + r.data.imgVO.imgByte;
					})
					.catch(axiosExtention.buildErrorHandler());
			}
			await this.jsWindowPopup(item, flag);
		},
		// CCTV 화면 레이아웃 변경
		divisionBtnClicked(_index) {
			this.divisionBtn.forEach((e, index) => {
				if (_index == index) {
					e.activeClass = 'actvie';
					// click index에 따라 cctv layout 변경
					if (index == 0) {
						this.division = 'div1';
					} else if (index == 1) {
						this.division = 'div4';
					} else if (index == 2) {
						this.division = 'div9';
					}
					VueCookies.set(this.loginInfo.userId + '_allSitedivisionOption', index, { expires: 365 });
				} else {
					e.activeClass = '';
				}
			});
		},
		// 왼쪽 cctv 및 스크린에서 cctv close 클릭 시
		cctvOnOff(fixIndex) {
			let cctvSwitch = document.getElementById('switch' + fixIndex);
			// CCTV가 켜져 있으면
			if (this.leftCctv[fixIndex].isOn) {
				this.leftCctv[fixIndex].class = ''; // 배경 색 없애기

				// img tag의 src를 null로 변경하지 않으면 네트워크를 계속 잡아먹는다.
				let img = document.getElementById('cctv-play-img' + this.leftCctv[fixIndex].cctvId);
				img.src = null;
				// img로부터 class=screen_item를 가진 가장 가까운 조상 찾기
				let screenItem = img.closest('.screen_item');
				// 삭제
				screenItem.remove();

				cctvSwitch.checked = false;
			} else {
				// CCTV 꺼져 있으면
				this.leftCctv[fixIndex].class = 'leftCctv'; // 배경 색 칠하기

				let screenList = document.getElementsByClassName('screen_list')[0];
				// 가독성 위해서 {} 추가함.. 필요없다고 생각되면 지울것
				{
					// li class=screen_item
					let screenItem = document.createElement('li');
					screenItem.classList.add('screen_item');
					screenList.appendChild(screenItem);
					{
						// div screen_wrap
						let screenWrap = document.createElement('div');
						screenWrap.classList.add('screen_wrap');
						screenItem.appendChild(screenWrap);
						{
							// div class=screen_box
							let screenBox = document.createElement('div');
							screenBox.classList.add('screen_box');
							screenBox.style.display = 'flex';
							screenWrap.appendChild(screenBox);
							{
								// img
								let img = document.createElement('img');
								img.setAttribute('id', 'cctv-play-img' + this.leftCctv[fixIndex].cctvId);
								img.src = this.leftCctv[fixIndex].cctvPlayUrl;
								screenBox.appendChild(img);
							}
							{
								// div class=sliding_info
								let slidingInfo = document.createElement('div');
								slidingInfo.classList.add('sliding_info');
								slidingInfo.style.width = '100%';
								slidingInfo.style.height = '100%';
								slidingInfo.style.backgroundColor = '#1b1e26';
								slidingInfo.setAttribute('id', 'cctv-sliding-div' + this.leftCctv[fixIndex].cctvId);
								screenBox.appendChild(slidingInfo);
							}
						}
						{
							// div class=screen_control
							let screenControl = document.createElement('div');
							screenControl.classList.add('screen_control');
							screenWrap.appendChild(screenControl);
							{
								// span class=cctv_name
								let cctvName = document.createElement('span');
								cctvName.classList.add('cctv_name');
								cctvName.textContent = this.leftCctv[fixIndex].cctvNm;
								screenControl.appendChild(cctvName);
							}
							{
								// div class=screen_btn
								let screenBtn = document.createElement('div');
								screenBtn.classList.add('screen_btn');
								screenControl.appendChild(screenBtn);
								{
									// a class=btn_full
									let btnFull = document.createElement('a');
									btnFull.classList.add('btn_full');
									btnFull.style.cursor = 'pointer';
									btnFull.textContent = '전체화면';
									btnFull.onclick = () => {
										return this.jsWindowPopup(this.leftCctv[fixIndex], 'fullScreen');
									};
									screenBtn.appendChild(btnFull);

									// a class=btn_close
									let btnClose = document.createElement('a');
									btnClose.classList.add('btn_close');
									btnClose.style.cursor = 'pointer';
									btnClose.textContent = '닫기';
									btnClose.onclick = () => {
										return this.cctvOnOff(fixIndex);
									};
									screenBtn.appendChild(btnClose);
								}
							}
						}
					}
				}

				cctvSwitch.checked = true;
			}
			this.leftCctv[fixIndex].isOn = !this.leftCctv[fixIndex].isOn;
		},
		removeWindow(popupDiv) {
			setTimeout(() => {
				popupDiv.style.display = 'none';
				var img = $(popupDiv).find('img')[0];
				if (img) {
					img.src = null;
				}
			}, this.secTimeSettingForRemovingWindow * 1000);
		},
		jsWindowPopupCheck(item, divScreen, popupOption, flag) {
			let popupChk = document.getElementById(flag + item.cctvId);
			let popupDiv;
			//해당 팝업이 이미 있으면 있던 걸 보여줌
			if (popupChk) {
				popupDiv = popupChk;
				let imgTag = popupDiv.querySelectorAll('img')[0];
				let titleBar = popupDiv.querySelectorAll('.window-js-title')[0];
				let titleName = '';
				if (flag == 'dangerOccurPopup' && this.dangerPopupOption == 'img') {
					imgTag.src = item.dangerOccurImg;
				} else {
					imgTag.src = item.cctvPlayUrl;
				}
				if (flag == 'dangerOccurPopup') {
					titleName = '위험 발생 ' + item.cctvNm + ': ' + item.objectNm + ' - ' + item.targetNm;
				} else {
					titleName = item.cctvNm;
				}
				titleBar.setAttribute('title', titleName);
				titleBar.textContent = titleName;
				window.show(popupDiv);
				//없으면 새로 만듦
			} else {
				popupDiv = document.createElement('div');
				popupDiv.id = flag + item.cctvId;
				divScreen.appendChild(popupDiv);

				window.create(popupDiv, popupOption);

				// video Element 생성
				let videoImg = document.createElement('img');
				// video src 세팅
				if (flag == 'dangerOccurPopup' && this.dangerPopupOption == 'img') {
					videoImg.src = item.dangerOccurImg;
				} else {
					videoImg.src = item.cctvPlayUrl;
				}
				// 'data:image/jpg;base64,' + r.data.imgVO.imgByte;
				// js window content에 video 붙여넣기
				let windowContent = window.getWindowContent();
				if (windowContent) {
					windowContent.appendChild(videoImg);
					windowContent.style.display = 'flex';
					window.show(popupDiv);
				}
			}
			return popupDiv;
		},
		jsWindowPopup(item, flag) {
			let divScreen = document.getElementsByClassName('screen_list');
			if (divScreen.length > 0) {
				divScreen = divScreen[0];
			} else {
				return;
			}
			let popupOption = {};
			if (flag == 'dangerOccurPopup') {
				popupOption = {
					width: '65vw',
					height: '80vh',
					title: '위험 발생 ' + item.cctvNm + ': ' + item.objectNm + ' - ' + item.targetNm,
				};
				let popupDiv = this.jsWindowPopupCheck(item, divScreen, popupOption, flag);
				this.removeWindow(popupDiv);
			} else if (flag == 'fullScreen') {
				popupOption = {
					width: '65vw',
					height: '80vh',
					title: item.cctvNm,
				};
				this.jsWindowPopupCheck(item, divScreen, popupOption, flag);
			}
		},
		refreshCctv() {
			this.leftCctv.forEach(e => {
				// cctv가 켜져있으면
				if (e.isOn) {
					let img = document.getElementById('cctv-play-img' + e.cctvId);
					// src 갱신
					img.src = e.cctvPlayUrl.split('?t=')[0] + '?t=' + new Date().getTime();
				}
			});
		},
		dangerPopupOptionChange(event) {
			if (event.target.value === 'img') {
				this.dangerPopupOption = 'img';
			} else if (event.target.value === 'live') {
				this.dangerPopupOption = 'live';
			} else {
				this.dangerPopupOption = 'img';
			}
			VueCookies.set(this.loginInfo.userId + '_dangerPopupOption', this.dangerPopupOption, { expires: 365 });
		},
	},
};
</script>

<style>
a {
	cursor: pointer;
}
.leftCctv {
	background: #1a1e36;
	background-clip: content-box;
}

@keyframes slidein {
	from {
		margin-top: 20%;
		/* margin-right: 30%; */
		margin-left: 30%;
		width: 50%;
	}
	to {
		margin-top: 0%;
		/* margin-right: 0%; */
		margin-left: 0%;
		width: 100%;
	}
}

.sliding_info {
	overflow: auto;
}
.sliding_info::-webkit-scrollbar {
	width: 5px;
}
.sliding_info::-webkit-scrollbar-thumb {
	background-color: white;
	border-radius: 10px;
}
.sliding_info::-webkit-scrollbar-track {
	background-color: #202127;
	border-radius: 10px;
	/* box-shadow: inset 0px 0px 5px white; */
}

.slide_in {
	animation-duration: 1s;
	animation-name: slidein;
}

.dangerOccurEvent {
	width: 100%;
	padding: 3px;
	word-break: normal;
	margin-top: 2px;
	margin-bottom: 4px;
	border: 0.3px solid #202127;
	border-radius: 10px;
	box-shadow: 2px 2px 2px gray;
	background-color: #e56060;
}

.dangerOccurEvent_actnInProgress {
	width: 100%;
	padding: 3px;
	word-break: normal;
	margin-top: 2px;
	margin-bottom: 4px;
	border: 0.3px solid #202127;
	border-radius: 10px;
	box-shadow: 2px 2px 2px gray;
	background-color: greenyellow;
}
.dangerOccurEventInfos {
	width: 100%;
	height: 100%;
	font-size: 10px;
	line-height: normal;
	text-align: left;
	overflow: auto;
}

.switch_wrapper {
	position: relative;
	float: right;
	padding-top: 5px;
}
.switch {
	position: absolute;
	/* hidden */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.switch_label {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 50px;
	height: 20px;
	background: #3f425648;
	border: 2px solid rgb(255, 255, 255);
	border-radius: 20px;
	transition: 0.2s;
}
.switch_label:hover {
	background: #cccbcb;
}
.onf_btn {
	position: absolute;
	top: 1px;
	left: 4px;
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 20px;
	background: rgb(255, 255, 255);
	transition: 0.2s;
}

.switch:checked + .switch_label {
	background: rgb(255, 255, 255);
	border: 2px solid rgb(255, 255, 255);
}

.switch:checked + .switch_label:hover {
	background: #cccbcb;
}

/* move */
.switch:checked + .switch_label .onf_btn {
	left: 30px;
	background: rgb(31, 31, 31);
	box-shadow: 1px 2px 3px #00000020;
}
</style>
