var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main", attrs: { id: "wrapper" } }, [
    _c("header", { attrs: { id: "header" } }, [
      _vm._m(0),
      _c("span", { staticClass: "header_date" }, [
        _vm._v(_vm._s(_vm.todayDate))
      ]),
      _c(
        "ul",
        { staticClass: "header_btn" },
        _vm._l(_vm.divisionBtn, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "btn_item",
              class: [item.n, item.activeClass]
            },
            [
              _c("a", {
                on: {
                  click: function($event) {
                    return _vm.divisionBtnClicked(index)
                  }
                }
              })
            ]
          )
        }),
        0
      )
    ]),
    _c("div", { attrs: { id: "container" } }, [
      _c("div", { staticClass: "cont_left_body" }, [
        _c("div", { staticClass: "situation" }, [
          _c("ul", { staticClass: "situation_list" }, [
            _c("li", { staticClass: "situation_item n1" }, [
              _c("span", { staticClass: "situation_count" }, [
                _c("em", [_vm._v(_vm._s(_vm.count.danger))]),
                _vm._v(" 건 ")
              ]),
              _c("span", { staticClass: "situation_title" }, [
                _vm._v("위험상황")
              ])
            ]),
            _c("li", { staticClass: "situation_item n2" }, [
              _c("span", { staticClass: "situation_count" }, [
                _c("em", [_vm._v(_vm._s(_vm.count.action))]),
                _vm._v(" 건 ")
              ]),
              _c("span", { staticClass: "situation_title" }, [_vm._v("조치중")])
            ])
          ])
        ]),
        _c("div", { staticClass: "cctv" }, [
          _c(
            "ul",
            { staticClass: "cctv_list" },
            _vm._l(_vm.leftCctv, function(item, index) {
              return _c(
                "li",
                { key: index, staticClass: "cctv_item", class: [item.class] },
                [
                  _c(
                    "span",
                    {
                      staticClass: "cctv_name",
                      class: [item.type],
                      staticStyle: {
                        width: "150px",
                        "white-space": "nowrap",
                        overflow: "hidden",
                        "text-overflow": "ellipsis"
                      },
                      attrs: { title: item.cctvNm }
                    },
                    [_vm._v(" " + _vm._s(item.cctvNm) + " ")]
                  ),
                  _c("div", { staticClass: "switch_wrapper" }, [
                    _c("input", {
                      staticClass: "switch",
                      attrs: { type: "checkbox", id: "switch" + index },
                      on: {
                        click: function($event) {
                          return _vm.cctvOnOff(index)
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "switch_label",
                        attrs: { for: "switch" + index }
                      },
                      [_c("span", { staticClass: "onf_btn" })]
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "cont_right_body" }, [
        _c("div", { staticClass: "cont_right_body_top" }, [
          _c("div", { staticClass: "caution" }, [
            _c("div", { staticClass: "caution_left" }, [
              _c("span", { staticClass: "caution_title" }, [
                _vm._v("주의작업")
              ]),
              _c("span", { staticClass: "caution_count" }, [
                _c("em", [_vm._v(_vm._s(_vm.count.caution))]),
                _vm._v(" 건 ")
              ])
            ]),
            _c("div", { staticClass: "caution_notice" }, [
              _c(
                "ul",
                {
                  staticClass: "notice_list",
                  staticStyle: { width: "inherit" }
                },
                _vm._l(_vm.dangerContent, function(item, index) {
                  return _c("li", { key: index, staticClass: "notice_item" }, [
                    _vm._v("· " + _vm._s(item.text))
                  ])
                }),
                0
              )
            ])
          ]),
          _c("div", { staticClass: "weather" }, [
            _c("ul", { staticClass: "weather_list" }, [
              _c("li", { staticClass: "weather_item temp" }, [
                _c("div", { staticClass: "temp_text1" }, [
                  _vm._v(_vm._s(_vm.weather.temperature) + "℃")
                ])
              ]),
              _c("li", { staticClass: "weather_item temp" }, [
                _c("div", { staticClass: "temp_text2" }, [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("↑" + _vm._s(_vm.weather.temperatureMax) + "℃")
                  ]),
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("↓" + _vm._s(_vm.weather.temperatureMin) + "℃")
                  ])
                ])
              ]),
              _vm._m(1),
              _vm._m(2),
              _c("li", { staticClass: "weather_item info" }, [
                _c("ul", { staticClass: "info_list" }, [
                  _c("li", { staticClass: "info_item" }),
                  _c("li", { staticClass: "info_item" }, [
                    _vm._v(" 습도 : "),
                    _c("em", [_vm._v(_vm._s(_vm.weather.humidity))]),
                    _vm._v(" % ")
                  ]),
                  _c("li", { staticClass: "info_item" }, [
                    _vm._v(" 풍속 : "),
                    _c("em", [_vm._v(_vm._s(_vm.weather.windSpeed))]),
                    _vm._v(" m/s ")
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "screen", class: [_vm.division] }, [
          _c("ul", { staticClass: "screen_list" })
        ])
      ])
    ]),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "logo" }, [
      _c("a", { staticStyle: { cursor: "default" } }, [
        _c("img", {
          attrs: {
            src: "/dashboardlib/images/common/logo.png",
            alt: "A safe / Always by your side 에이세이프"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "weather_item rain" }, [
      _c("div", { staticClass: "rain_icon type01" }, [_vm._v("맑음")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "weather_item rain" }, [
      _c("div", { staticClass: "rain_text" }, [_vm._v("0mm")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { attrs: { id: "footer" } }, [
      _c("span", { staticClass: "footer_copy" }, [
        _vm._v("Copyright © AIKL SYSTEM Inc. All Rights Reserved.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }