let _bMouseDown = false;
let _deltaX = 0;
let _deltaY = 0;

let _opOverlapable = true;
let _opWindowDisplayOffsetTop = 0;
let _opWindowDisplayOffsetBottom = 0;
let _opWindowDisplayOffsetLeft = 0;
let _opWindowDisplayOffsetRight = 0;

let content = undefined;

const getWindowContent = function () {
	return content;
};

const show = function (rootDiv) {
	// 전체 비활성
	var domActive = document.querySelectorAll('.window-js.active');
	var activeCnt = domActive.length;
	for (var i = 0; i < activeCnt; i++) {
		domActive[i].classList.remove('active');
	}

	if (!rootDiv.classList.contains('show') && !rootDiv.classList.contains('change')) {
		rootDiv.classList.add('show');
		rootDiv.classList.remove('hide');
	} else {
		rootDiv.classList.remove('hide');
	}
	rootDiv.style.display = 'block';

	_setNotOverlapPosition(rootDiv);

	rootDiv.tabIndex = 0;

	_setInnerPosition(rootDiv);
	_toFront(rootDiv);
};

// 타이틀 클릭 event bind
const _onTitleMouseDown = function (e) {
	let rootDiv = $(e.target).closest('.window-js')[0];

	if (!_bMouseDown) {
		var windowRect = rootDiv.getBoundingClientRect();

		if (e.preventDefault) {
			e.preventDefault();
		} else {
			e.returnValue = false;
		}

		_bMouseDown = true;
		_deltaX = e.clientX - windowRect.left;
		_deltaY = e.clientY - windowRect.top;

		_toFront(rootDiv);
	}
};

const _onTitleMouseMove = function (e) {
	let rootDiv = $(e.target).closest('.window-js')[0];

	if (_bMouseDown) {
		var domRootStyle = rootDiv.style;
		var windowRect = rootDiv.getBoundingClientRect();
		var innerWidth = window.innerWidth;
		var innerHeight = window.innerHeight;

		var windowDisplayOffsetTop = _opWindowDisplayOffsetTop;
		var windowDisplayOffsetBottom = _opWindowDisplayOffsetBottom;
		var windowDisplayOffsetLeft = _opWindowDisplayOffsetLeft;
		var windowDisplayOffsetRight = _opWindowDisplayOffsetRight;

		var deltaX = _deltaX;
		var deltaY = _deltaY;
		var moveLeft = e.clientX - deltaX;
		// var moveRight = e.clientX - deltaX + windowRect.width;
		var moveTop = e.clientY - deltaY;
		//var moveBottom = e.clientY - deltaY + windowRect.height;
		var moveBottom = e.clientY - deltaY + 30;

		this._isMinMoved = true;

		// horizontal boundary
		if (moveLeft <= windowDisplayOffsetLeft) {
			// left boundary
			domRootStyle.left = windowDisplayOffsetLeft + 'px';
		} else if (moveLeft >= innerWidth - windowRect.width - windowDisplayOffsetRight) {
			//right boundary
			domRootStyle.left = innerWidth - windowRect.width - windowDisplayOffsetRight + 'px';
		} else {
			domRootStyle.left = moveLeft + 'px';
		}

		// vertical boundary
		if (moveTop <= windowDisplayOffsetTop || windowRect.top + 0.5 < windowDisplayOffsetTop) {
			// top boundary
			domRootStyle.top = windowDisplayOffsetTop + 'px';
		} else if (moveBottom >= innerHeight - windowDisplayOffsetBottom) {
			// bottom boundary
			domRootStyle.top = innerHeight - 30 - windowDisplayOffsetBottom + 'px';
		} else {
			domRootStyle.top = moveTop + 'px';
		}
	}
};

const _onTitleMouseUp = function () {
	if (_bMouseDown) {
		_bMouseDown = false;
	}
};

const close = function (e) {
	var rootDiv = $(e.target).closest('.window-js')[0];
	rootDiv.style.display = 'none';
	var img = $(rootDiv).find('img')[0];
	if (img) {
		img.src = null;
	}
	// rootDiv.remove();
};

const _toFront = function (e) {
	var rootDiv;

	if (e.target) {
		rootDiv = $(e.target).closest('.window-js')[0];
	} else {
		rootDiv = e;
	}

	var elems = document.getElementsByClassName('window-js');
	var maxZindex = elems.length;
	var view = document.defaultView;
	var frontZindex = view.getComputedStyle(rootDiv, null).getPropertyValue('z-index');

	if (frontZindex == 'auto') {
		rootDiv.style.zIndex = parseInt(maxZindex);
	} else {
		frontZindex = parseInt(frontZindex);
	}

	// front가 max zIndex 값이 아니면
	if (frontZindex != maxZindex) {
		for (var i = 0; i < maxZindex; i++) {
			var elemZindex = view.getComputedStyle(elems[i], null).getPropertyValue('z-index'); // z-index 값
			if (elemZindex >= frontZindex) {
				// front 보다 z-index가 크면 -1
				if (elemZindex <= 0) {
					elems[i].style.zIndex = 1;
				} else {
					elems[i].style.zIndex = elemZindex - 1;
				}
			}
		}
		rootDiv.style.zIndex = parseInt(maxZindex);
	}
};

// 다른 window와 같은 위치 있을 시 위치 증가
const _setNotOverlapPosition = function (rootDiv) {
	if (!_opOverlapable) {
		var windows = document.querySelectorAll('.window-js.show');

		for (var i = 0; i < windows.length; i++) {
			var window = windows[i];

			var offset = 30;
			if (window != rootDiv) {
				if (window.style.left == rootDiv.style.left && window.style.top == rootDiv.style.top) {
					rootDiv.style.left = parseInt(rootDiv.style.left) + offset + 'px';
					rootDiv.style.top = parseInt(rootDiv.style.top) + offset + 'px';
				}
			}
		}
	}
};

// 브라우저 밖으로 넘어갈 경우 브라우저 안으로 위치 이동
const _setInnerPosition = function (rootDiv) {
	var scale = rootDiv.getBoundingClientRect().width / rootDiv.offsetWidth;
	var innerWidth = window.innerWidth;
	var width = parseFloat(window.getComputedStyle(rootDiv, null).width) * scale;
	// var height = parseFloat(window.getComputedStyle(rootDiv, null).height) * scale;

	if (parseFloat(rootDiv.style.left) <= _opWindowDisplayOffsetLeft) {
		// 좌
		rootDiv.style.left = _opWindowDisplayOffsetLeft + 'px';
	}
	if (parseInt(width) + parseInt(rootDiv.style.left) >= innerWidth) {
		// 우
		rootDiv.style.left = innerWidth - parseInt(width) - 2 + 'px';
	}
	if (parseFloat(rootDiv.style.top) <= _opWindowDisplayOffsetTop) {
		// 상
		console.log(_opWindowDisplayOffsetTop);
		rootDiv.style.top = _opWindowDisplayOffsetTop + 'px';
	} else if (parseInt(rootDiv.style.top) >= innerHeight - 30) {
		// 하
		rootDiv.style.top = innerHeight - 30 + 'px';
	}
};

const create = function (/* String(id of DOM) or DOM */ div, /* Object */ options) {
	var domRoot = undefined;

	// 최상위 DOM 요소 설정

	if (typeof div == 'string') {
		domRoot = document.querySelector('#' + div);
	} else {
		domRoot = div;
	}

	var opWidth = options.width ? options.width : '800px';
	var opHeight = options.height ? options.height : '450px';

	var opTop = options.top ? options.top : '150px';
	var opLeft = options.left ? options.left : '320px';
	var opRight = options.right;

	var opTitle = options.title ? options.title : 'Window';

	var opOnClosed = options.onClosed ? options.onClosed : undefined;

	var opOverlapable = options.overlapable != undefined ? options.overlapable : false; // 중첩 가능 여부

	var opIsVCenter = options.isVCenter ? options.isVCenter : false;
	var opIsHCenter = options.isHCenter ? options.isHCenter : false;

	// window move inner 범위 지정
	var opWindowDisplayOffsetTop = options.windowDisplayOffsetTop ? parseInt(options.windowDisplayOffsetTop) : 135;
	var opWindowDisplayOffsetBottom = options.windowDisplayOffsetBottom ? parseInt(options.windowDisplayOffsetBottom) : 0;
	var opWindowDisplayOffsetLeft = options.windowDisplayOffsetLeft ? parseInt(options.windowDisplayOffsetLeft) : 290;
	var opWindowDisplayOffsetRight = options.windowDisplayOffsetRight ? parseInt(options.windowDisplayOffsetRight) : 0;

	domRoot.classList.add('window-js');

	var domRootStyle = domRoot.style;
	domRootStyle.width = opWidth;
	domRootStyle.height = opHeight;
	if (opRight) {
		domRootStyle.left = innerWidth - parseInt(opRight) - parseFloat(opWidth) + 'px';
	} else {
		domRootStyle.left = opLeft;
	}
	domRootStyle.top = opTop;
	domRootStyle.display = 'none';

	if (opIsVCenter) {
		if (opWidth == 'auto') {
			domRootStyle.left = window.innerWidth / 2 - 50 + 'px';
		} else {
			domRootStyle.left = window.innerWidth / 2 - parseFloat(opWidth) / 2 + 'px';
		}
	}
	if (opIsHCenter) {
		if (opWidth == 'auto') {
			domRootStyle.top = window.innerHeight / 2 - 50 + 'px';
		} else {
			domRootStyle.top = window.innerHeight / 2 - parseFloat(opHeight) / 2 + 'px';
		}
	}

	// 자식 DOM 요소 생성 및 설정

	// Title Element
	var domTitlebar = document.createElement('div');
	domTitlebar.classList.add('window-js-titlebar');

	var domTitle = document.createElement('span');
	if (opWidth != 'auto') {
		domTitle.style.width = parseFloat(opWidth) - 70 + 'px';
	}
	domTitle.classList.add('window-js-title');
	domTitle.setAttribute('title', opTitle);
	domTitle.textContent = opTitle;
	domTitlebar.appendChild(domTitle);

	var domClose = document.createElement('span');
	domClose.classList.add('window-js-close');
	domClose.setAttribute('title', '닫기');
	domClose.textContent = '\u00D7';
	domTitlebar.appendChild(domClose);

	domRoot.insertBefore(domTitlebar, domRoot.firstChild);

	// Content Element
	var titlebarStyle = window.getComputedStyle(domTitlebar, null);
	//px로 적용할 때
	// var contentHeight = parseFloat(opHeight) - parseFloat(titlebarStyle.height);
	//vh로 적용할 때
	var contentHeight = parseFloat(opHeight) - (parseFloat(titlebarStyle.height) * 100) / document.documentElement.clientHeight;
	console.log();
	var domContent = document.createElement('div');
	domContent.classList.add('window-js-content');
	domContent.style.width = opWidth;
	domContent.style.height = contentHeight + 'vh'; //or px
	domRoot.appendChild(domContent);

	// 이벤트 설정
	// Title Bar Click Event
	domTitlebar.addEventListener('mousedown', _onTitleMouseDown);
	domTitlebar.addEventListener('mousemove', _onTitleMouseMove);
	domTitlebar.addEventListener('mouseout', _onTitleMouseUp);
	domTitlebar.addEventListener('mouseup', _onTitleMouseUp);

	// Close Button Click Event
	domClose.addEventListener('click', close);

	// Window Content Click Event
	domRoot.addEventListener('mousedown', _toFront);

	domRoot._instance = this;

	// 지역 변수 설정
	this._bMouseDown = false;
	this._deltaX = 0;
	this._deltaY = 0;
	this._onClosed = opOnClosed;

	// DOM 전역 객체 변수
	this.domRoot = domRoot;
	this.domTitlebar = domTitlebar;
	this.domTitle = domTitle;
	content = domContent;
	this.domClose = domClose;

	this.opRight = opRight;
	this.opWidth = opWidth;
	this.opHeight = opHeight;
	this.opIsVCenter = opIsVCenter;
	this.opIsHCenter = opIsHCenter;
	_opOverlapable = opOverlapable;

	// 브라우저 밖으로 나가지 않기위해 값 설정
	// 초기값
	_opWindowDisplayOffsetTop = opWindowDisplayOffsetTop;
	_opWindowDisplayOffsetBottom = opWindowDisplayOffsetBottom;
	_opWindowDisplayOffsetLeft = opWindowDisplayOffsetLeft;
	_opWindowDisplayOffsetRight = opWindowDisplayOffsetRight;

	//div Id
	// _divId = div;
};

export default {
	create,
	show,
	close,
	getWindowContent,
};
